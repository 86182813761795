<template>
  <modal
      @closed="handleClose"
      adaptive
      :max-width="900"
      :max-height="400"
      name="point-info-modal"
      class="point-info-modal"
  >
    <div class="flex flex-col justify-between h-full">
      <div class="flex flex-col items-center justify-between p-6">
        <div class="text-center">
          <ul class="text-xl">
            <li>Point id: <b>{{ point.id }}</b></li>
            <li>Status: <b>{{ point.status }}</b></li>
            <li>Address: <b>{{ point.address }}</b></li>
            <li style="color: red">owner: <b>{{ point.owner }}</b></li>
            <li>date: <b>{{ `${point.date}`.replaceAll('/','.') }}</b></li>
            <li>GPS: <b>{{ point.latitude }}, {{point.longitude}}</b></li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col sm:flex-row justify-between p-5">
        <button
          v-if="canActions && (point.status !== 'inpost' || point.status !== 'k' || point.status !== 'r') && point.isPrivate"
          class="p-2 px-6 rounded-xl text-xl font-bold text-white"
          style="background-color: dodgerblue;"
          @click="handleEdit"
        >
          Edit
        </button>
        <button
          v-if="canActions && (point.status !== 'inpost' || point.status !== 'k' || point.status !== 'r') && point.isPrivate"
          class="p-2 px-6 rounded-xl text-xl font-bold text-white ml-3"
          style="background-color: #ee1818;"
          @click="handleDelete"
        >
          Delete
        </button>
        <button class="p-2 px-6 rounded-xl text-xl font-bold close-btn ml-auto" @click="handleClose">Zamknij</button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "PointInfoModal",
  props: {
    showModal: {
      type: Boolean,
    },
    point: {
      type: Object,
      required: true,
    },
    canActions: {
      type: Boolean,
    }
  },
  watch: {
    showModal(value) {
      value ? this.show() : this.hide();
    },
  },
  methods: {
    show () {
      this.$modal.show('point-info-modal');
    },
    hide () {
      this.$modal.hide('point-info-modal');
    },
    handleClose() {
      this.$emit('close-modal');
    },
    handleDelete() {
      this.$emit('delete-point');
    },
    handleEdit() {
      this.$emit('edit-point');
    },
  },
}
</script>

<style scoped>
li {
  font-size: 1.15rem;
  /*margin-top: 15px;*/
}
</style>
