<template>
  <modal
      @closed="handleClose"
      adaptive
      :max-width="900"
      width="700"
      :height="'auto'"
      name="auth-modal"
      :class="{'auth-modal': !isLoggedIn}"
      :clickToClose="isLoggedIn ? true : false"
  >
    <div  v-if="isLoggedIn" class="flex flex-col  p-6">
        <span style="align-self: center">Are you sure you want to log out?</span>
        <button @click="logout" class="p-2 mt-2 px-6 rounded-xl text-xl font-bold submit-btn">Log me out</button>
    </div>
    <div  v-else class="flex flex-col justify-between h-full p-6">
      <form class="login-form">
        <input class="mr-2 rounded p-1 mt-2" v-model="login" type="text" placeholder="Login" autocomplete="username"/>
        <input class="mr-2 rounded p-1 mt-2" v-model="password" type="password" placeholder="Password"
               autocomplete="current-password"/>
        <button @click="submit" class="p-2 mt-2 px-6 rounded-xl text-xl font-bold submit-btn">Zaloguj sie</button>
      </form>
    </div>
  </modal>
</template>

<script>
import {auth} from "@/api";
import {notify} from "@/utils/notifications";
import {removeToken} from "@/services/axiosInstance";

export default {
  name: "AuthModal",
  data: () => ({
    login: "",
    password: ""
  }),
  props: {
    showModal: {
      type: Boolean,
    },
    showLogout: {
      type: Boolean,
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    showModal(value) {
      value ? this.show() : this.hide();
    },
  },
  methods: {
    logout(){
      removeToken()
      this.$emit('logout');
      this.$emit('close-modal');
    },
    async submit(arg) {
      arg.preventDefault();
      try {
        const res = await auth({login: this.login, password: this.password})
        this.$emit('login')
        localStorage.setItem('map-token', res.data && res.data.token)
        this.$modal.hide('auth-modal');
      }
      catch (e) {
        console.error(e);
        notify({
          type: 'error',
          title: 'Error',
          text: e.response.data.message
        });
      }
    },
    show() {
      this.$modal.show('auth-modal');
    },
    hide() {
      this.$modal.hide('auth-modal');
    },
    handleClose() {
      this.$emit('close-modal');
    },
  },
}
</script>

<style scoped>
.table-points {
  border-collapse: collapse;
}

.table-points tr {
  height: 30px;
}

.table-points, tr, td {
  border: 1px solid black;
}
.login-form {
  display: flex;
  flex-direction: column;
}
.login-form input {
  border-width: 2px;
  border-color: #908787;
  border-radius: 10px;
}
.submit-btn {
  background-color: #fc8c14;
  border-color: #f83a3a;
  color: #fff;
  background-image: -webkit-linear-gradient(45deg, #f83a3a 50%, transparent 50%);
  background-image: linear-gradient(45deg, #f83a3a 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 400ms ease-in-out;
  transition: background 400ms ease-in-out;
  align-self: center;
}
.auth-modal {
  background: rgba(0, 0, 0, 0.95);
}
</style>
