<template>
  <div id="app">
    <Map />
    <notifications style="padding-top: 15px;" :max="3" position="top center" group="notify" />
  </div>
</template>

<script>

import Map from "@/views/Map";
export default {
  name: 'App',
  components: {Map},
}
</script>

<style>
body {
  margin: 0;
}

* *:focus{
  outline: none!important;
}

.vm--modal {
  border-radius: 20px!important;
  border: 3px solid #4F9AFB;
}

.close-btn {
  background-color: #fc8c14;
  border-color: #f83a3a;
  color: #fff;
  background-image: -webkit-linear-gradient(45deg, #f83a3a 50%, transparent 50%);
  background-image: linear-gradient(45deg, #f83a3a 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 400ms ease-in-out;
  transition: background 400ms ease-in-out;
}
.close-btn:hover {
  background-position: 0;
}

</style>
