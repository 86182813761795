export const statuses = [
  {id: 7, value: '--NONE--', color: 'black'},
  // STATUSES FOR POINTS FROM CAINIAO_MACHINES:
  {id: 1, value: 'target', color: 'orange', opacity: 0},
  {id: 2, value: 'contacted', color: 'brown'},
  {id: 3, value: 'offered_waiting', color: 'blue'},
  {id: 4, value: 'agreed', color: 'green'},
  {id: 5, value: 'ready_to_install_locker', color: 'purple'},
  {id: 6, value: 'active', color: 'red'}
  // STATUSES FOR POINTS FROM CSL_CRM:
  // {id: 10, value: '20_ACCEPTED_BY_ALI_STEP_1', color: 'green'},
  // {id: 11, value: '30_PHOTOS_AND_QUESTIONNAIRE', color: 'blue'},
  // {id: 12, value: '40_ACCEPTED_BY_ALI_STEP_2', color: 'blue'},
  // {id: 13, value: '60_LOCATION_COST_ACCEPTED', color: 'blue'},
  // {id: 14, value: '70_ACCEPT_VENTOR', color: 'blue'},
  // {id: 15, value: '80_CONTRACT_ANALYSIS', color: 'blue'},
  // {id: 16, value: '85_CONTRACT_OK', color: 'blue'},
  // {id: 17, value: '90_CONTRACT_SIGNED', color: 'blue'},
  // {id: 18, value: '100_FINAL_COST_ACCEPTED', color: 'blue'},
  // {id: 19, value: '110_INSTALLATION_PLANNED', color: 'purple'},
  // {id: 20, value: '120_INSTALLATION_FINISHED', color: 'red'},
  // {id: 21, value: '130_LOCKER_ACTIVATED', color: 'red'},
  // {id: 22, value: '135_INSTALLER_COMPANY_SELECTED', color: 'blue'},
  // {id: 23, value: '140_INSTALLER_COMPANY_ACCEPTED', color: 'blue'},
  // {id: 24, value: '150_INSTALLER_COMPANY_COST_PROVIDED', color: 'blue'},
  // {id: 25, value: '900_REJECTED_BY_ALI_1', color: 'black', opacity: 0},
  // {id: 26, value: '910_REJECTED_BY_ALI_2', color: 'black', opacity: 0},
  // {id: 27, value: '920_REJECTED_BY_VENTOR', color: 'white', opacity: 0},
  // {id: 28, value: '930_REJECTED_BY_SP', color: 'white', opacity: 0}
];