import Vue from 'vue';
import App from './App.vue';
import Notifications from 'vue-notification';
import ToggleButton from 'vue-js-toggle-button';
import router from './router';
import * as VueGoogleMaps from 'vue2-google-maps';
import VModal from 'vue-js-modal';

Vue.config.productionTip = false;
Vue.use(VModal);
Vue.use(Notifications);
Vue.use(ToggleButton);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAjMwIj5r41wAdLuR4NdIuk-rFpCwBM41E',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
  },
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
