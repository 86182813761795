<template>
  <div class="main-page">
    <HeaderComponent
        @open-settings="handleShowSettingsModal(true)"
        @open-points-list="handleShowPointsListModal(true)"
        :is-logged-in="isLoggedIn"
        @logout-modal="handleShowAuthModal(true)"
    />
    <div class="main-map relative">
      <div class="absolute search-input">
        <gmap-autocomplete
            placeholder="Szukaj..."
            :options="{fields: ['geometry', 'address_components'], strictBounds: true}"
            :select-first-on-enter="true"
            @place_changed="setPlace">
        </gmap-autocomplete>
      </div>

      <GmapMap
          :center="center"
          :zoom="zoom"
          :options="mapOptions"
          class="map-class"
          style="width: 100%; height: 100%"
          ref="gmap"
          @click="handleClickMap"
          @bounds_changed="handleChangeBounds"
      >
        <template v-if="mapLoaded">
          <Gmap-Marker
            v-if="autocompletePlace"
            :position="{
              lat: autocompletePlace.geometry.location.lat(),
              lng: autocompletePlace.geometry.location.lng(),
            }"
          ></Gmap-Marker>
          <gmap-cluster v-if="showINPPoints" :max-zoom="groupMarkers ? 19 : 0">
            <GmapMarker
                v-for="(point) in INPPoints"
                :key="point.id"
                :position="{lat: Number(point.latitude), lng: Number(point.longitude)}"
                :icon="{
                  url:getIcons('INP'),
                  size: {width: 25, height: 30, f: 'px', b: 'px'},
                  scaledSize: {width: 25, height: 30, f: 'px', b: 'px'},

                }"
                @mouseover="handleHoverPoint(point)"
                @mouseout="handleHoverPoint({})"
                @click="handleClickPoint(point)"
            />
          </gmap-cluster>
          <GmapCircle
              v-for="(point, index) in filteredOwnPoints"
              :key="index"
              :center="{lat: Number(point.latitude), lng: Number(point.longitude)}"
              :radius="circleRadiusOwn"
              :visible="true"
              :options="{
              fillColor: circleColor(point.status),
              fillOpacity:circleOpacity(point.status),
              cursor: mapOptions.draggableCursor
            }"
              @click="handleClickMap"
          ></GmapCircle>
          <gmap-cluster :max-zoom="groupMarkers ? 19 : 0">
            <GmapMarker
                v-for="(point) in filteredOwnPoints"
                :key="point.id"
                :icon="{
                  url: customMarker(point.status),
                  size: {width: 20, height: 30, f: 'px', b: 'px'},
                  scaledSize: {width: 20, height: 30, f: 'px', b: 'px'},
                }"
                :position="{lat: Number(point.latitude), lng: Number(point.longitude)}"
                @mouseover="handleHoverPoint(point)"
                @mouseout="handleHoverPoint({})"
                @click="handleClickPoint(point)"
            />
          </gmap-cluster>
          <gmap-cluster v-if="showRPoints" :max-zoom="groupMarkers ? 19 : 0">
            <GmapMarker
                    v-for="(point) in rPoints"
                    :key="point.id"
                    :icon="{
                      url:getIcons('R'),
                      size: {width: 20, height: 35, f: 'px', b: 'px'},
                      scaledSize: {width: 20, height: 35, f: 'px', b: 'px'},
                    }"
                    :position="{lat: Number(point.latitude), lng: Number(point.longitude)}"
                    @mouseover="handleHoverPoint(point)"
                    @mouseout="handleHoverPoint({})"
                    @click="handleClickPoint(point)"
            />
          </gmap-cluster>
          <gmap-cluster v-if="showKPoints"  :max-zoom="groupMarkers ? 19 : 0">
            <GmapMarker
                    v-for="(point) in kPoints"
                    :key="point.id"
                    :icon="{
                      url:getIcons('K'),
                      size: {width: 20, height: 30, f: 'px', b: 'px'},
                      scaledSize: {width: 20, height: 30, f: 'px', b: 'px'},
                    }"
                    :position="{lat: Number(point.latitude), lng: Number(point.longitude)}"
                    @mouseover="handleHoverPoint(point)"
                    @mouseout="handleHoverPoint({})"
                    @click="handleClickPoint(point)"
            />
          </gmap-cluster>
          <gmap-cluster v-if="showHalterPoints"  :max-zoom="groupMarkers ? 19 : 0">
            <GmapMarker
                v-for="(point) in halterPoints"
                :key="point.id"
                :icon="{
                      url: getIcons('Halter'),
                      size: {width: 20, height: 30, f: 'px', b: 'px'},
                      scaledSize: {width: 20, height: 30, f: 'px', b: 'px'},
                    }"
                :position="{lat: Number(point.latitude), lng: Number(point.longitude)}"
                @mouseover="handleHoverPoint(point)"
                @mouseout="handleHoverPoint({})"
                @click="handleClickPoint(point)"
            />
        </gmap-cluster>
          <gmap-cluster v-if="showTopPoints"  :max-zoom="groupMarkers ? 19 : 0">
            <GmapMarker
                v-for="(point) in topPoints"
                :key="point.id"
                :icon="{
                      url: getIcons('TOP'),
                      size: {width: 25, height: 25, f: 'px', b: 'px'},
                      scaledSize: {width: 25, height: 25, f: 'px', b: 'px'},
                    }"
                :position="{lat: Number(point.latitude), lng: Number(point.longitude)}"
                @mouseover="handleHoverPoint(point)"
                @mouseout="handleHoverPoint({})"
                @click="handleClickPoint(point)"
            />
          </gmap-cluster>
          <gmap-cluster v-if="showMaxPoints"  :max-zoom="groupMarkers ? 19 : 0">
            <GmapMarker
                v-for="(point) in maxPoints"
                :key="point.id"
                :icon="{
                      url: getIcons('Maksymalne'),
                      size: {width: 25, height: 25, f: 'px', b: 'px'},
                      scaledSize: {width: 25, height: 25, f: 'px', b: 'px'},
                    }"
                :position="{lat: Number(point.latitude), lng: Number(point.longitude)}"
                @mouseover="handleHoverPoint(point)"
                @mouseout="handleHoverPoint({})"
                @click="handleClickPoint(point)"
            />
          </gmap-cluster>
          <gmap-cluster v-if="showHotSpotsPoints"  :max-zoom="groupMarkers ? 19 : 0">
            <GmapMarker
                v-for="(point) in hotSpotsPoints"
                :key="point.id"
                :icon="{
                      url: getIcons('HotSpots'),
                      size: {width: 25, height: 25, f: 'px', b: 'px'},
                      scaledSize: {width: 25, height: 25, f: 'px', b: 'px'},
                    }"
                :position="{lat: Number(point.latitude), lng: Number(point.longitude)}"
                @mouseover="handleHoverPoint(point)"
                @mouseout="handleHoverPoint({})"
                @click="handleClickPoint(point)"
            />
          </gmap-cluster>
          <GmapCircle
              v-for="(point, index) in hotSpotsPoints"
              :key="`${index}_${point.status}`"
              :center="{lat: Number(point.latitude), lng: Number(point.longitude)}"
              :radius="circleRadiusHotSpot"
              :visible="true"
              :options="{
              fillColor: '#befac1',
              fillOpacity:0.4,
              cursor: mapOptions.draggableCursor
            }"
              @click="handleClickMap"
          ></GmapCircle>
          <gmap-cluster v-if="showInpTopPoints"  :max-zoom="groupMarkers ? 19 : 0">
            <GmapMarker
                v-for="(point) in inpTopPoints"
                :key="point.id"
                :icon="{
                      url: getIcons('InpTop'),
                      size: {width: 25, height: 25, f: 'px', b: 'px'},
                      scaledSize: {width: 25, height: 25, f: 'px', b: 'px'},
                    }"
                :position="{lat: Number(point.latitude), lng: Number(point.longitude)}"
                @mouseover="handleHoverPoint(point)"
                @mouseout="handleHoverPoint({})"
                @click="handleClickPoint(point)"
            />
          </gmap-cluster>
        </template>
<!--        <gmap-marker-->
<!--            style="width: 400px; fontSize: 50px"-->
<!--            :key="index"-->
<!--            v-for="(m, index) in mapLabels"-->
<!--            :position="m"-->
<!--            label="LABEL"-->
<!--            icon='m.icon'-->
<!--            @click="center=m"-->
<!--        ></gmap-marker>-->
<!--        <gmap-marker-->
<!--            :key="item.label.length"-->
<!--            v-for="item in mapLabels"-->
<!--            :label='item.label'-->
<!--            :position="{lat:item.lat, lng:item.lng}"-->
<!--        >-->
<!--        </gmap-marker>-->

<!-- RESTRICTION CIRCLE-->
          <GmapCircle
              v-for="(restriction, index) in restrictions"
              :key="'restriction_'+index"
              :center="{lat: Number(restriction.latitude), lng: Number(restriction.longitude)}"
              :radius="restriction.radius"
              :visible="true"
              :options="{
              strokeColor: restrictionOptions.strokeColor,
              strokeOpacity: restrictionOptions.strokeOpacity,
              strokeWeight: restrictionOptions.strokeWeight,
              fillColor: restrictionOptions.fillColor,
              fillOpacity: restrictionOptions.fillOpacity,
              cursor: mapOptions.draggableCursor
            }"
              @click="handleClickMap"
          ></GmapCircle>
      </GmapMap>
      <div
          v-if="hoveredPoint.id"
          class="tooltip"
          :style="{borderColor: hoveredPoint.color}"
      >
        <div>
          <b>id: {{ hoveredPoint.id }}</b> | Status: {{ hoveredPoint.status }}
        </div>
      </div>

    </div>
    <PointInfoModal
        :point="selectedPoint"
        :show-modal="showPointInfoModal"
        :can-actions="editModeActivated"
        @edit-point="handleEditPoint"
        @delete-point="handleDeletePoint"
        @close-modal="handleClosePointInfoModal"
    />
    <AddEditPointModal
        :point-for-edit="pointForEdit"
        :show-modal="showAddEditPointModal"
        @add-point="addNewPoint"
        @edit-point="editPoint"
        @close-modal="handleCloseNewEditPointModal"
    />
    <AppSettingsModal
        :edit-mode="editModeActivated"
        :show-modal="showSettingsModal"
        :show-inp-points="showINPPoints"
        :show-r-points="showRPoints"
        :show-k-points="showKPoints"
        :show-halter-points="showHalterPoints"
        :show-top-points="showTopPoints"
        :show-max-points="showMaxPoints"
        :show-HotSpots-points="showHotSpotsPoints"
        :show-InpTop-points="showInpTopPoints"
        :group-markers="groupMarkers"
        :selected-filter="selectedFilter"
        :private-filter="privateFilter"
        :types="types"
        @change-show-inp="handleChangeShowINP"
        @change-show-r="handleChangeShowR"
        @change-show-k="handleChangeShowK"
        @change-show-halter="handleChangeShowHalter"
        @change-show-top="handleChangeShowTop"
        @change-show-max="handleChangeShowMax"
        @change-show-hotSpots="handleChangeShowHotSpots"
        @change-show-inpTop="handleChangeShowInpTop"
        @select-filter="setFilter"
        @group-markers="handleChangeGroupMarkers"
        @close-modal="handleShowSettingsModal(false)"
        @activate-edit-mode="setEditMode"
        @handle-private="handlePrivate"
    />
    <PointsListModal
        :points="ownPoints"
        :show-modal="showPointsListModal"
        :loading="loading"
        @go-to-point="handleGoToPoint"
        @close-modal="handleShowPointsListModal(false)"
    />
    <AuthModal
        :show-modal="showAuthModal"
        :loading="loading"
        :isLoggedIn="isLoggedIn"
        :showLogout="true"
        @go-to-point="handleGoToPoint"
        @close-modal="handleShowAuthModal(false)"
        @login="isLoggedIn = true;  getRestrictions();"
        @logout="isLoggedIn = false; fetchPoints();"
    />
    <div v-if="loading" class="loader-wrapper absolute">
      <div
          class="loader-inner rounded-t-lg flex flex-row items-center p-2 justify-center"
      >
        <div class="text-white font-bold text-xl mr-2">Ładowanie</div>
        <div class="loader"></div>
      </div>
    </div>
    <div
        v-if="hoveredPoly"
        :class="[
          'hovered-poly',
          'rounded-r-xl',

          'absolute',
          'p-2',
          {'pt-0':hoveredPoly && hoveredPoly.layer}
        ]"
    >
      <div class="text-2xl">
        <template>
<!--          <template v-if="hoveredPoly.layer">-->
<!--            <span class="text-sm pt-0 pb-0" >{{ hoveredPoly.layer }} | </span>-->
<!--            <br>-->
<!--          </template>-->
          {{ hoveredPoly.name }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce';
import HeaderComponent from "@/components/HeaderComponent";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import PointInfoModal from "@/components/PointInfoModal";
import AddEditPointModal from "@/components/AddEditPointModal";
import { statuses } from "@/utils/constants";
import AppSettingsModal from "@/components/AppSettingsModal";
import {notify} from "@/utils/notifications";
import {
  getOwnPoints,
  getINPPoints,
  getHalterPoints,
  addNewPoint,
  editPoint,
  deletePoint,
  getKPoints,
  getRPoints,
  getHotSpotsPoints,
  getInpTopPoints,
  getTopPoints, getMaxPoints, getTypes, getRestrictions
} from "@/api";
import PointsListModal from "@/components/PointsListModal";
import AuthModal from "@/components/AuthModal";
import {AUTH_TOKEN} from "@/services/axiosInstance";
import { parse } from 'wkt';
import polygons from "@/utils/polygons";
export default {
  name: "Map",
  components: {
    AuthModal,
    PointsListModal, AppSettingsModal, AddEditPointModal, PointInfoModal, HeaderComponent, 'gmap-cluster': GmapCluster},
  data: () => ({
    mapLoaded: false,
    isLoggedIn: false,
    center: {lat: 52.237049, lng: 19.7},
    circleRadiusOwn: 300,
    circleRadiusHotSpot: 250,
    zoom: 7,
    groupMarkers: true,
    editModeActivated: false,
    corners: {},
    loading: false,
    autocompletePlace: null,
    mapOptions: {
      maxZoom: 20,
      streetViewControl: false,
      draggableCursor: ''
    },
    INPPoints: [],
    selectedFilter: '--ALL--',
    ownPoints: [],
    rPoints: [],
    kPoints: [],
    halterPoints: [],
    topPoints: [],
    maxPoints: [],
    hotSpotsPoints: [],
    inpTopPoints: [],
    filteredOwnPoints: [],
    hoveredPoint: {},
    selectedPoint: {},
    pointForEdit: {},
    newPointCoordinates: {},
    showINPPoints: false,
    showKPoints: false,
    showRPoints: false,
    showHalterPoints: false,
    showTopPoints: false,
    showMaxPoints: false,
    showHotSpotsPoints: false,
    showInpTopPoints: false,
    showPointInfoModal: false,
    showAddEditPointModal: false,
    showSettingsModal: false,
    showPointsListModal: false,
    showAuthModal: false,
    types: [],
    hoveredPoly: null,
    restrictions: null,
    privateFilter: '',
    restrictionOptions: {
      strokeColor: 'red',
      strokeOpacity: 0.75,
      strokeWeight: 4,
      fillColor: "#FF0000",
      fillOpacity: 0,
    },
  }),
  async mounted() {
    this.getRestrictions()
    await this.$refs.gmap.$mapPromise;
    this.mapLoaded = true;
    if (AUTH_TOKEN)
      {
      this.isLoggedIn = true
    }
    const types = await getTypes()
    this.types = types.data



    this.initSettings();
     const geoJsonFromWKT = (wktArray) => {
      const arrayOfFeatures = wktArray.map((polygon) => {
        return ({
          type: "Feature",
          geometry: parse(polygon['geometry']),
          properties: {
            color: polygon.color || '#606060',
            jpt_code: polygon.jpt_code,
            id: polygon.id,
            name: polygon.name,
            layer: polygon.layer,
            coverage: polygon.coverage,
            parent: polygon.parent,
          },
        })
      })

      return ({type: "FeatureCollection", features: arrayOfFeatures});
    };

    const getColorForPercentage = (percentage) => {
      const percent = percentage / 100;

      let red = 255;
      let green = 255;
      if (percent >= 0 && percent <= 0.5) {
        green = 510 * percent;
      } else if (percent > 0.5 && percent <= 1) {
        red = -510 * percent + 510;
      }

      return 'rgb(' + [red, green, 0].join(',') + ')';
    }
    const geoJson = geoJsonFromWKT(polygons);
    this.$refs.gmap.$mapPromise.then((map) => {
      map.data.addGeoJson(geoJson);
      map.data.setStyle((feature) => {
        const fillColorValue = this.showCoverage ? getColorForPercentage(feature.getProperty("coverage")) : feature.getProperty("color");
        return {
          fillColor: fillColorValue,
          strokeWeight: 0.5,
          cursor: this.mapOptions.draggableCursor
        };
      });
    });

    const map = await this.$refs.gmap.$mapPromise
    this.mapLoaded = true;
    // map.data.addListener('click', (value) => {
    //   this.handleClickPoly(value)
    // })
    map.data.addListener('mouseover', (value) => {
      this.handleHoverPoly(value.feature.j ? value.feature.j : value.feature.i);
    })
    map.data.addListener('click', (value) => {
      this.handleClickMap(value);
    })
    map.data.addListener('mouseout', () => {
      this.handleHoverPoly(null);
    })



  },
  watch: {
    selectedFilter(val, oldVal) {
      val !== oldVal && this.filterOwnPoints();
    },
    isLoggedIn() {
      this.fetchPoints()
    },
    privateFilter( val, oldVal) {
      val !== oldVal && this.filterOwnPoints();
    }
  },
  methods: {
    getIcons(type) {
      if (this.types.length) {
        const icon = this.types.find(element => element.title.toLowerCase() === type.toLowerCase())
        return icon ? icon.icon : require(`@/images/${type}.png`)
      }
      else return 'http://maps.google.com/mapfiles/kml/paddle/red-circle.png'
    },
    handleHoverPoly(poly) {
      this.hoveredPoly = poly;
    },
    initSettings() {
      if (localStorage.getItem('groupMarkers') !== null) {
        this.groupMarkers = !!JSON.parse(localStorage.getItem('groupMarkers'));
      }
    },
    circleColor(status) {
      let color = ''
      for (let i = 0; i < statuses.length; i += 1) {
        if (statuses[i].value === status) {
          color = statuses[i].color
          break;
        }
      }
      return color;
    },
    circleOpacity(status) {
      let opacity = 0.6
      for (let i = 0; i < statuses.length; i += 1) {
        if (statuses[i].value === status) {
          opacity = statuses[i].opacity
          break;
        }
      }
      return opacity;
    },
    customMarker(status) {
      let markerUrl = '';
      for (let i = 0; i < statuses.length; i += 1) {
        if (statuses[i].value === status) {
          markerUrl = `http://labs.google.com/ridefinder/images/mm_20_${statuses[i].color}.png`;
          break;
        }
      }
      return markerUrl.toString();
    },
    setPlace(place) {
      this.autocompletePlace = place
      if(this.autocompletePlace) {
        this.center = {
          lat: this.autocompletePlace.geometry.location.lat(),
          lng: this.autocompletePlace.geometry.location.lng()
        }
        this.zoom = 13;
      }
      },
    handleShowSettingsModal(status) {
      this.showSettingsModal = status;
    },
    handleHoverPoint(point) {
      this.hoveredPoint = point;
    },
    handleClickPoint(point) {
      this.selectedPoint = point;
      this.showPointInfoModal = true;
    },
    handleClosePointInfoModal() {
      this.showPointInfoModal = false;
    },
    handleShowPointsListModal(status) {
      this.showPointsListModal = status;
    },
    handleShowAuthModal(status) {
      this.showAuthModal = status;
    },
    handlePrivate(value) {
      this.privateFilter = value
    },
    handleEditPoint() {
      this.showPointInfoModal = false;
      this.pointForEdit = this.selectedPoint;
      this.showAddEditPointModal = true;
    },
    handleDeletePoint() {
      if (window.confirm("Do you really want to delete point?")) {
        this.showPointInfoModal = false;
        this.deletePoint(this.selectedPoint.id);
      }
    },
    handleCloseNewEditPointModal() {
      this.showAddEditPointModal = false;
      this.pointForEdit = {};
    },
    handleChangeShowINP(val) {
      this.showINPPoints = val;
      if (val) {
        this.fetchPoints();
      } else {
        this.INPPoints = [];
      }
    },
    handleChangeShowR(val) {
      this.showRPoints = val;
      if (val) {
        this.fetchPoints();
      } else {
        this.RPoints = [];
      }
    },
    handleChangeShowK(val) {
      this.showKPoints = val;
      if (val) {
        this.fetchPoints();
      } else {
        this.KPoints = [];
      }
    },
    handleChangeShowTop(val) {
      this.showTopPoints = val;
      if (val) {
        this.fetchPoints();
      } else {
        this.topPoints = [];
      }
    },
    handleChangeShowHotSpots(val) {
      this.showHotSpotsPoints = val;
      if (val) {
        this.fetchPoints();
      } else {
        this.hotSpotsPoints = [];
      }
    },
    handleChangeShowMax(val) {
      this.showMaxPoints = val;
      if (val) {
        this.fetchPoints();
      } else {
        this.maxPoints = [];
      }
    },
    handleChangeShowInpTop(val) {
      this.showInpTopPoints = val;
      if (val) {
        this.fetchPoints();
      } else {
        this.inpTopPoints = [];
      }
    },
    handleChangeShowHalter(val) {
      this.showHalterPoints = val;
      if (val) {
        this.fetchPoints();
      } else {
        this.halterPoints = [];
      }
    },
    handleChangeGroupMarkers(val) {
      this.groupMarkers = val;
      localStorage.setItem('groupMarkers', val);
    },
    handleClickMap(point) {
      if (this.editModeActivated) {
        this.newPointCoordinates = {longitude: point.latLng.lng(), latitude: point.latLng.lat()};
        this.showAddEditPointModal = true;
      }
    },
    handleRequestError(e) {
      if (e.response.status === 401){
        this.isLoggedIn = false;
        this.handleShowAuthModal(true)
      }
      else {
        notify({
          type: 'error',
          title: 'Error',
          text: e.message
        });
      }
    },
    setFilter(filter) {
      this.selectedFilter = filter;
    },
    setEditMode(mode) {
      this.editModeActivated = mode;
      if (mode) {
        this.mapOptions.draggableCursor = 'crosshair'
        this.$refs.gmap.$mapPromise.then((map) => {
          map.data.setStyle(() => {
            return {
              cursor: this.mapOptions.draggableCursor
            };
          });
        });
      }
      else {
        this.mapOptions.draggableCursor = ''
        this.$refs.gmap.$mapPromise.then((map) => {
          map.data.setStyle(() => {
            return {
              cursor: this.mapOptions.draggableCursor
            };
          });
        });
      }
    },
    handleGoToPoint(point) {
      this.showPointsListModal = false;
      this.center = {
        lat: Number(point.latitude),
        lng: Number(point.longitude)
      }
      this.zoom = 16;
      if (point.status !== this.selectedFilter) {
        this.selectedFilter = '--ALL--'
        this.filterOwnPoints();
      }
    },
    filterOwnPoints() {
      if ( this.selectedFilter === '--ALL--') {
        this.filteredOwnPoints = this.privateFilter === 'Private' ?  this.ownPoints.filter(point => point.isPrivate) :
            this.privateFilter === 'non-Private' ? this.ownPoints.filter(point => !point.isPrivate) :
            this.ownPoints;
      }
      else if (this.selectedFilter === '--NONE--') {
        this.filteredOwnPoints = [];
      }
      else {
        this.filteredOwnPoints = this.privateFilter === 'Private' ? this.ownPoints.filter(point => point.isPrivate && (point.status ===  this.selectedFilter)) :
            this.privateFilter === 'non-Private' ?    this.ownPoints.filter(point => !point.isPrivate && (point.status ===  this.selectedFilter)) :
            this.ownPoints.filter(point => point.status ===  this.selectedFilter);

      }
    },
    handleChangeBounds: debounce ( function (bounds) {
      const southWest = bounds.getSouthWest();
      const northEast = bounds.getNorthEast();
      this.corners = {
        southWest: {
          latitude: southWest.lat(), longitude: southWest.lng()
        },
        northEast: {
          latitude: northEast.lat(), longitude: northEast.lng()
        }
      };
        this.fetchPoints();
    }, 400),
    async getRestrictions() {
      try {
        const res = await getRestrictions()
        this.restrictions = res.data.restriction;
        if (res.data.options) {
          this.restrictionOptions = res.data.options
        }
      }
      catch (e) {
        this.handleRequestError(e)
      }
    },
    async fetchPoints() {
      this.loading  = true;

      try {
        const results = await Promise.all([
          getOwnPoints(this.corners),
          this.showKPoints && getKPoints(this.corners),
          this.showRPoints && getRPoints(this.corners),
          this.showINPPoints && getINPPoints(this.corners),
          this.showHalterPoints && getHalterPoints(this.corners),
          this.showTopPoints && getTopPoints(this.corners),
          this.showMaxPoints && getMaxPoints(this.corners),
          this.showHotSpotsPoints && getHotSpotsPoints(this.corners),
          this.showInpTopPoints && getInpTopPoints(this.corners)
        ]);

        this.ownPoints = results[0].data;
        this.kPoints =  results[1].data;
        this.rPoints = results[2].data;
        this.INPPoints = results[3].data;
        this.halterPoints = results[4].data;
        this.topPoints = results[5].data;
        this.maxPoints = results[6].data;
        this.hotSpotsPoints = results[7].data;
        this.inpTopPoints = results[8].data;
        this.filterOwnPoints();

      } catch (e) {
        console.error(e);
        this.handleRequestError(e)
      }
      this.loading  = false;
    },
    async addNewPoint(pointData) {
      try {
        const {data: point} = await addNewPoint({...pointData, ...this.newPointCoordinates});

        this.ownPoints.push(point);
        notify({
          type: 'success',
          duration: 5000,
          title: 'Sukces',
          text: 'Punkt został pomyślnie dodany'
        });
        this.filterOwnPoints();
      } catch (e) {
        console.error(e)
        this.handleRequestError(e)
      }
      this.handleCloseNewEditPointModal();
    },
    async editPoint(pointData) {
      try {
        await editPoint({...pointData})
        notify({
          type: 'success',
          duration: 5000,
          title: 'Sukces',
          text: 'Punkt został pomyślnie edytowany'
        });
        const indexOfEdited = this.ownPoints.findIndex(point => point.id === pointData.id);
        this.ownPoints[indexOfEdited] = {...pointData};
        this.filterOwnPoints();
      } catch (e) {
        console.error(e)
        this.handleRequestError(e)
      }
      this.handleCloseNewEditPointModal();
    },
    async deletePoint(pointId) {
      try {
        await deletePoint({id: pointId})
        const indexOfDeleted = this.ownPoints.findIndex(point => point.id === pointId)
        this.ownPoints.splice(indexOfDeleted, 1);
        this.filterOwnPoints();
        notify({
          type: 'success',
          duration: 5000,
          title: 'Sukces',
          text: 'Punkt został pomyślnie usunięty'
        });
      } catch (e) {
        console.error(e)
        this.handleRequestError(e)
      }
    },
  }
}
</script>

<style scoped>
.main-page {
  display: flex;
  flex-direction: column;
  height:100vh;
}

.main-map {
  flex: 1 0 auto;
}

.search-input {
  top: 0;
  left: 0;
  z-index: 2;
}

.search-input input {
  width: 280px;
  padding: 7px 14px;
  border-bottom-right-radius: 7px;
  outline: none!important;
  border: 1px solid black;
  border-top-width: 0;
  border-left-width: 0;
  -moz-box-shadow: 2px 2px 5px rgba(68,68,68,0.4);
  -webkit-box-shadow: 2px 2px 5px rgba(68,68,68,0.4);
  box-shadow: 2px 2px 5px rgba(68,68,68,0.4);
}

.tooltip {
  position: absolute;
  padding: 10px 12px;
  font-size: 1.75rem;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background-color: whitesmoke;
  border: 5px solid;
  border-left-width: 0;
  left: 0;
  bottom: 60px;
  -moz-box-shadow: 5px 5px 5px rgba(68,68,68,0.6);
  -webkit-box-shadow: 5px 5px 5px rgba(68,68,68,0.6);
  box-shadow: 5px 5px 5px rgba(68,68,68,0.6);
}

.loader-wrapper {
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.loader-inner {
  width: 160px;
  background-color: #4F9AFB;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #4F9AFB;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
}
.hovered-poly {
  background-color: whitesmoke;
  border-width: 5px;
  border-left-width: 0;
  left: 0;
  bottom: 60px;
  -moz-box-shadow: 5px 5px 5px rgba(68,68,68,0.6);
  -webkit-box-shadow: 5px 5px 5px rgba(68,68,68,0.6);
  box-shadow: 5px 5px 5px rgba(68,68,68,0.6);
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.map-class {
  cursor: crosshair;
}
</style>

<style>
.gmnoprint:first-child {
  margin: 50px 10px 10px 10px!important;
}
</style>


