<template>
  <modal
      @closed="handleClose"
      adaptive
      :max-width="900"
      width="700"
      :height="'auto'"
      name="points-list-modal"
  >
    <div class="flex flex-col justify-between h-full p-6">
      <div
          class="flex flex-col items-center pt-3"
      >
        <template v-if="loading">
          <h2 class="text-3xl font-bold">
            Please wait
          </h2>
        </template>
        <template v-else>
          <template v-if="points.length">
            <h2 class="text-3xl font-bold mb-3">
              Points
            </h2>
            <p class="mb-2 text-left self-start">Filter:
              <select @change="handleSelectFilter($event)" class="text-black p-2 mr-3 ml-3 rounded-lg">
                <option
                    v-for="filter in filterOptions"
                    :key="filter.id"
                    :value="filter.value"
                    :selected="statusFilter === filter.value"
                >
                  {{ filter.value }}
                </option>
              </select>
            </p>
            <div style="height: 350px; border-color: gray;" class="w-full overflow-auto rounded border-2">
              <table class="w-full table-points">
                <tr class="text-center font-bold">
                  <td>Id</td>
                  <td>Status</td>
                  <td>Address</td>
                  <td>Point</td>
                </tr>
                <tr v-for="point in filteredPoints" :key="point.id" class="text-center">
                  <td>{{ point.id }}</td>
                  <td>{{ point.status }}</td>
                  <td>{{ point.address }}</td>
                  <td>
                    <span class="text-blue-500 font-bold cursor-pointer" @click="goToPoint(point)">Show</span>
                  </td>
                </tr>
              </table>
            </div>
          </template>
          <h2 class="text-3xl font-bold" v-else>
            No points
          </h2>
        </template>
      </div>
      <div class="text-right p-3 pb-0">
        <button class="p-2 px-6 rounded-xl text-xl font-bold close-btn" @click="handleClose">Zamknij</button>
      </div>
    </div>
  </modal>
</template>

<script>
import {statuses} from "@/utils/constants";

export default {
  name: "PointsListModal",
  data: () => ({
    filteredPoints: [],
    statusFilter: '--ALL--',
    filterOptions:   [{id: 0, value: '--ALL--'}, ...statuses],
  }),
  props: {
    showModal: {
      type: Boolean,
    },
    points: {
      type: Array,
      default: () => ([]),
    },
    loading: {
      type: Boolean,
    }
  },
  watch: {
    showModal(value) {
      value ? this.show() : this.hide();
    },
    points(val) {
      val.length && this.filterPoints();
    }
  },
  methods: {
    show() {
      this.$modal.show('points-list-modal');
    },
    hide() {
      this.$modal.hide('points-list-modal');
    },
    handleClose() {
      this.$emit('close-modal');
    },
    goToPoint(point) {
      this.$emit('go-to-point', point)
    },
    handleSelectFilter(filter) {
      this.statusFilter = filter.target.value;
      this.filterPoints();
    },
    filterPoints() {
      if (this.statusFilter === "--ALL--") {
        this.filteredPoints = this.points;
      } else {
        this.filteredPoints = this.points.filter(point => point.status === this.statusFilter)
      }
    },
  },
}
</script>

<style scoped>
.table-points {
  border-collapse: collapse;
}

.table-points tr {
  height: 30px;
}

.table-points, tr, td {
  border: 1px solid black;
}

</style>
