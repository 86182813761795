import axiosSource from 'axios';
// import {notify} from "@/utils/notifications";

export const axios = axiosSource.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

const loadToken = () => localStorage.getItem('map-token');
export const AUTH_TOKEN = loadToken();

axios.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('map-token')
    config.headers.common["X-CM-Token"] = token;
    return config;
    },
);

export const removeToken = () => {
    localStorage.removeItem('map-token');
    delete axios.defaults.headers.common["X-CM-Token"];
}


// axios.interceptors.response.use(
//   res => res,
//   err => {
//     notify({
//       type: 'error',
//       title: 'Error',
//       text: err.message
//     });
//     throw new Error(err);
//   }
// )