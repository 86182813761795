import { render, staticRenderFns } from "./PointInfoModal.vue?vue&type=template&id=52252120&scoped=true&"
import script from "./PointInfoModal.vue?vue&type=script&lang=js&"
export * from "./PointInfoModal.vue?vue&type=script&lang=js&"
import style0 from "./PointInfoModal.vue?vue&type=style&index=0&id=52252120&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52252120",
  null
  
)

export default component.exports