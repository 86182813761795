import Vue from 'vue';
import Router from 'vue-router';
import Map from "@/views/Map";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Map,
    },
  ]
})
